.td__total {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #000000 !important;
}

.td__detail {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #000000 !important;
  border-top: 1px solid #C4C4C4 !important;
  border-bottom: 1px solid #C4C4C4 !important;
  padding-top: 2em !important;
  padding-bottom: 2em !important;
}

.td__head {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #000000 !important;
}

.transaction__detail th {
  border: none !important;
  border-top: none !important;
  border-bottom: none !important;
  cursor: pointer;
}

.br-0 {
  border-right: none !important;
}

.bl-0 {
  border-left: none !important;
}

.bt-0 {
  border-top: none !important;
}

.bb-0 {
  border-bottom: none !important;
}

.btb-0 {
  border-bottom: none !important;
  border-top: none !important;
}

.td__a-total {
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #BD443D !important;
}

.table th,.table td {
  min-width: 140px;
}