
.dashboard__heading {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 24px !important;
  line-height: 31px;
  color: #000000;
}



.dashboard__label {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
}

.dashboard__sub {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
}

.active_dash {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #000000 !important;
}

.dashboard__tab-button {
  cursor: pointer;
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #9C9696;
}

.dashboard__tab-amenties-title {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: #797979;
}


.dasboard__tab-amenties-button {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;

  color: #FFBB24;
}

.dashboard__transaction-pay-button {
  background-color: #000000 !important;
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 18px !important;

  color: #FFFFFF !important;
}

.transaction__card span {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;

  color: #000000 !important;
}

.transaction__all-table th,
.transaction__all-table td {
  border: none !important;
  border-top: none !important;
  cursor: pointer;
}


.transaction__all-table th {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #797979;
}

.transaction__all-table td {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: #000000;
}

.transaction__success {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 17px !important;
  line-height: 18px !important;
  color: #009688 !important;
}


.transaction__expired {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 17px !important;
  line-height: 18px !important;
  color: #BD443D !important;
}
