.header__space-link {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #000000;
}

.btn__back {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19px !important;
  background-color: #000000 !important;
  color: #FFFFFF !important;
}

.btn__book {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19px !important;

  color: #000000 !important;
}

@media only screen and (min-width: 600px) {
  .modal__book {
    max-width: 48vw !important;
  }
}

.modal__book {
  max-width: 95vw !important;
}

.modal__book-title {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 47px;

  color: #000000;
}

p {
  font-family: PT Sans Caption !important;
}

.modal__book-caption {

  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;

  color: #000000;
}

.modal__book-form input,
.modal__book-form select,
.modal__book-form textarea {
  font-family: PT Sans Caption !important;
  border: 1px solid #000000 !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 19px !important;

  color: #9C9696;
}

.modal__book-send {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 23px !important;
  background-color: #000000 !important;
  color: #FFFFFF !important;
  width: 204px;
  height: 58px;

}