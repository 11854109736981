.space__locations-heading {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 47px;
  color: #FFFBFB;
}

.space__contact-h5 {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

.div__svg {
  height: 42px !important;
    width: 42px !important;
    text-align: center;
    margin: 0 auto;
}

.space__contact-p {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.space__card-title {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;

  color: #000000;
}

.space__card-caption {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #000000;
}

.space__btn-product {
  font-family: PT Sans Caption;
  font-style: normal;;
  font-weight: bold;
  font-size: 14px !important;
  line-height: 18px;
  color: #000000 !important;
  background-color: #C4C4C4 !important;
}

.space__btn-product svg {
  margin-left: .5em;
}

.benefit__sub {
  font-size: 20px !important;
  font-weight: bold;
}


@media only screen and (min-width: 600px) {
  .benefit__sub {
    font-size: 30px;
    font-weight: bold;
  }
}