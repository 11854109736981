.nav__item {
  color: #000 !important;
}

.icon__user {
  display: inline !important;
  position: relative !important;
  right: unset;
  top: unset;
}

.modalActive {
  color: #FFBB24;
}
