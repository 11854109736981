/**
 * Styles for the Header.js component
 */

header {
  svg {
    position: absolute;
    right: -20%;
    top: 2px;
  }
}

.navbar-brand {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-size: 0.9rem;
}

.dropdown-item {
  &, a {
    color: #473BE7;
    text-decoration: none !important;
  }

  .active, &:active {
    &, a {
      color: #fff;
    }
  }
}

