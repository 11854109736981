.footer {
  background: #000000;
  position: absolute;
  width: 100%;
  bottom:0;
  left:0;
}

.footer__cp-text {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #FFFFFF;
}

.footer__img {
  vertical-align: middle;
}