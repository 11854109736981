.header__coffee-link {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #000000;
}

.btn__back-coffee {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19px !important;
  background-color: #000000 !important;
  color: #FFFFFF !important;
}
