/**
 * Styles for the Sidebar.js component
 */

.sidebar {
  position: fixed;
  top: 56px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #22262E;
  border-right: 1px solid #252931;

  .nav {
    margin-bottom: 20px;
  }

  .nav-item {
    width: 100%;

    & + .nav-item {
      margin-left: 0;
    }
  }

  .nav-link {
    color: #6A6E74;
    font-size: 0.9rem;
    padding: 0.75rem 1rem;

    i {
      font-size: 1.2rem;
      margin-right: 0.4rem;
    }

    span {
      position: absolute;
    }

    &.active, &:hover {
      color: white;
    }

    &.active {
      background: #141519;
    }
  }
}
