.footer {
  background: #000000;
  border: none !important;
}

footer {
  border: none !important;
}

.footer__cp-text {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #FFFFFF;
}

.footer__img {
  vertical-align: middle;
}
