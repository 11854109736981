.tp__heading {
  font-size: 18px !important;
  line-height: 22px !important;
  color: #000000 !important;
  font-weight: bolder;
}

.tp__rekening {
  font-size: 24px !important;
  line-height: 29px !important;
  color: #000000 !important;
  font-weight: bolder;
}

.td__wrapper p {
  font-size: 18px !important;
  line-height: 22px !important;
  color: #000000 !important;
}

.td__total-p {
  font-size: 24px !important;
  line-height: 29px !important;
  color: #D65B5B !important;
}

.fileContainer {
  border: none;
  box-shadow: none;
}