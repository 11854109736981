.box-white {
  position: absolute;
  left: 0%;
  right: 78.25%;
  top: 0.82%;
  bottom: 0%;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.kuningan__text-align {
  text-align: justify;
  font-family: PT Sans Caption;
}

.kuningan__location {
  text-align: left;
}

.kuningan__location h3 {
  font-weight: bold;
  font-size: 45px;
}

.kuningan__facility {
  text-align: left;
}

.kuningan__facility h3 {
  font-weight: bold;
  font-size: 45px;
}

.kuningan__space {
  height: 435.07px;
  border: 1px solid #000000;
  position: relative;
}

.shared__align {
  text-align: justify;
}

.shared__align h2 {
  font-weight: bold;
  font-family: PT Sans Caption !important;
  font-size: 36px;
}

.shared__align p {
  font-family: PT Sans Caption !important;
  font-size: 14px;
}

.space__background {
  min-height: 600px;
  background-repeat: no-repeat;
  background-position: right;
}

.space__paragraph {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  min-height: 48px;
  max-height: 48px;
  overflow: hidden;
}

img {
  width: 100%;
}

.b__card {
  border-radius: 20px !important;
}

.b__card img {
  border-radius: 20px 20px 0 0 !important;
}

.btn__location {
  background: #C4C4C4 !important;
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 19px !important;
  color: #000000 !important;
  border: none !important;
  padding: .4em 1.5em !important;
}

.space__contact {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 47px;
  color: #000000;
}

.space__card-title {
  min-height: 39px;
  max-height: 39px;
  overflow: hidden;
}

.space__card {
  margin-top: -11em;
  background: white;
  margin-left: 20px;
  width: 170px;
  min-height: 150px;
  max-height: 150px;
}

.font-card {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;

  color: #000000;

}

.font-p {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 17px;

  color: #3C3B3B;

}

.space__button {
  font-size: 13px !important;
  color: black !important;
  background-color: #c4c4c4 !important;
}

.space__facility {
  font-size: small;
  border: 1px solid #000000;
  box-sizing: border-box;
}

.space__bg-color {
  background: url('../Space/Images/background-black.png');
  background-size: auto;
  margin-bottom: 6.5em;
  padding-bottom: 2em;
  padding-top: 2em;
}

@media only screen and (min-width: 600px) {
  .space__bg-color {
    width: 100%;
    height: 73%;
    margin-top: 6em;
    min-height: 400px;
    max-height: 400px;
    padding-bottom: 0;
    padding-top: 0;
  }
}


.space__bg-color p {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #FFFFFF;
}

.space__bg-color h1 {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 44px;
  color: #FFFFFF;
}

.location__product-title {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.location__product_caption {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.location__space-title {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 65px;

  color: #000000;

}

.product__space-sub {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;

  color: #000000;

}

.wrapper_book {
  left: 20%;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.wrapper_book button {
  background: #000000;
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
}

.book__date-picker {
  height: 38px;
  padding-left: .5em;
  border: 1px solid black;
  color: black;
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

h1 {
  font-family: PT Sans Caption;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 36px !important;
  line-height: 47px !important;

  color: #000000;

}


.product__sign-btn {
  font-family: PT Sans Caption;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 23px !important;

  color: #FFFFFF;
  background-color: #000000 !important;
}


@media only screen and (min-width: 600px) {
  .about__image {
    width: 100%;
    height: 73%;
    margin-top: 6em;
  }
}

.iframe__wrapper iframe {
  width: 100% !important;
  height: 400px;
}