@import url('https://fonts.googleapis.com/css?family=PT+Sans+Caption&display=swap');

@media only screen and (max-width: 600px) {
  .home__box {
    margin-top: 0px !important;
  }
}

.home__box {
  background-color: white;
  margin-top:3em;
}

.coffee__h1 {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 47px;
  color: #000000;
}

.coffee__contact {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 47px;
  color: #000000;
}

.tetot {
  max-height: 72.5vh !important;
}

.home__box-title {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 47px;
  color: #090909;
}

.home__box-caption {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.home__border-p {
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  min-height: 400px;
}

.home__border-p span {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  text-align: center !important;
  color: #000000 !important;
  display: block;
  min-height: 55px !important;
}

.home__bg-color {
  background: url('/about.jpg');
  background-size: cover;
  min-height: 30vh;
  max-height: 60vh;
  background-repeat: round;
}

.home__bg-color h3 {
  color: white;
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 47px;
}

.home__bg-color p {
  color: white;
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
}

.coffee__background {
  min-height: 600px;
  background-repeat: no-repeat;
  background-position: right;
}

.coffee__contact-h5 {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}

.coffee__contact-p {
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.activeCT {
  color: #FFFFFF !important;
  background-color: #050505 !important;
}



.menu__toggle {
  background-color: rgb(196, 196, 196);
  font-family: PT Sans Caption;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 26px !important;
  color: #050505;
  padding: 1em 2.5em;
  flex-wrap: nowrap;
  cursor: pointer;
}


@media only screen and (min-width: 600px) {
  .menu__toggle {
    font-size: 20px !important;
    line-height: 26px !important;
    padding: 1em 3.5em;
    cursor: pointer;
  }
}


.product__name {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #000000 !important;
}

.product__price {
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #000000 !important;
}

.product__cta {
  background: #000000 !important;
  font-family: PT Sans Caption !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 13px !important;
  line-height: 17px !important;
  color: #FFFFFF !important;
  padding: 1em !important;
}

.wa__text {
  display: inline-block;
  font-family: PT Sans Caption;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  margin-left:.5em;
  margin-bottom: 0;
  color: #FFFFFF;
}
.wa__btn {
  background: #000000 !important;
}
